export function addTouchListeners(el: HTMLElement, callback: any) {
    const touchElement = el;
    let touchEvent: string;
    let startX: number;
    let startY: number;
    let distX: number;
    let distY: number;
    const threshold = 150; // required min distance traveled to be considered swipe
    const restraint = 100; // maximum distance allowed at the same time in perpendicular direction
    const allowedTime = 300; // maximum time allowed to travel that distance
    let elapsedTime: number;
    let startTime: number;
    const handleTouch = callback || function () {};

    touchElement.addEventListener(
        'touchstart',
        function (e) {
            const touchobj = e.changedTouches[0];
            touchEvent = 'none';
            distX = 0;
            distY = 0;
            startX = touchobj.pageX;
            startY = touchobj.pageY;
            startTime = new Date().getTime(); // record time when finger first makes contact with surface

            handleTouch('start');
        },
        { passive: true }
    );

    touchElement.addEventListener(
        'touchmove',
        function (e) {
            const touchobj = e.changedTouches[0];
            distX = touchobj.pageX - startX; // get horizontal dist traveled by finger while in contact with surface
            distY = touchobj.pageY - startY; // get vertical dist traveled by finger while in contact with surface
            elapsedTime = new Date().getTime() - startTime; // get time elapsed

            if (
                elapsedTime <= allowedTime &&
                Math.abs(distY) < Math.abs(distX)
            ) {
                /* Prevent scrolling if:
                    user has dragged their finger horizontally more than vertically
                    AND
                    user has been dragging for less than the 'allowedTime'
                */
                e.preventDefault();
            }
        },
        { passive: true }
    );

    touchElement.addEventListener(
        'touchend',
        function (e) {
            const touchobj = e.changedTouches[0];
            distX = touchobj.pageX - startX; // get horizontal dist traveled by finger while in contact with surface
            distY = touchobj.pageY - startY; // get vertical dist traveled by finger while in contact with surface
            elapsedTime = new Date().getTime() - startTime; // get time elapsed
            if (elapsedTime <= allowedTime) {
                // first condition for swipe met
                if (
                    Math.abs(distX) >= threshold &&
                    Math.abs(distY) <= restraint
                ) {
                    // 2nd condition for horizontal swipe met
                    touchEvent = distX < 0 ? 'swipe-left' : 'swipe-right'; // if dist traveled is negative, it indicates left swipe
                } else if (
                    Math.abs(distY) >= threshold &&
                    Math.abs(distX) <= restraint
                ) {
                    // 2nd condition for vertical swipe met
                    touchEvent = distY < 0 ? 'swipe-up' : 'swipe-down'; // if dist traveled is negative, it indicates up swipe
                } else {
                    touchEvent = 'tap';
                }
            } else {
                touchEvent = 'end-hold';
            }

            if (touchEvent !== 'tap') {
                e.preventDefault();
            }

            handleTouch(touchEvent);
        },
        false
    );
}

export function removeTouchListeners(el: HTMLElement) {
    // replacing with a clone triggers all the event listeners to be removed
    el.replaceWith(el.cloneNode(true));
}
